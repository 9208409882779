import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { MainNavLink } from './MainNavLink';
import { LanguageSelector } from './LanguageSelector';
import { SocialNav } from './SocialNav';
import { FooterArrow } from './FooterArrow';
import { FooterLink } from './FooterLink';
import { CompanyContact } from './CompanyContact';

const MainNavWrapper = styled.nav`
  position: fixed;
  height: calc(100% - 80px);
  width: 0;
  background-color: ${colors.secondaryText};
  z-index: 1003;
  overflow: hidden;
  transition: width 500ms cubic-bezier(1, 0, 0, 1) 0ms, left 500ms cubic-bezier(1, 0, 0, 1) 0ms;

  @media (max-width: 767px) {
    height: 100%;
  }

  @media (min-width: 768px) {
    .language-selector {
      display: none;
    }
  }

  .language-selector {
    margin-top: 4rem;
    font-size: 1.2rem;
    margin-left: 3rem;
    position: absolute;
  }

  .menu-color-strip {
    height: 100%;
    width: 120px;
    background-color: ${colors.secondaryText};
    position: absolute;
    right: 0;
    transition: background-color 500ms cubic-bezier(1, 0, 0, 1) 40ms, left 500ms cubic-bezier(1, 0, 0, 1) 40ms;

    @media (max-width: 767px) {
      margin-right: 2.2rem;
      width: 64px;
    }
  }

  @media (min-width: 768px) {
    &.main-nav-toggle-hovered {
      width: 45px;
    }
  }

  &.main-nav-open {
    width: calc(90% - 80px);

    .menu-color-strip {
      background-color: ${colors.primaryColor};
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .main-menu-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 10rem;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 5rem;
    }

    .links-and-address {
      flex: 1;
      display: flex;
      align-items: center;

      .social-nav {
        transform: translateX(-100%);

        &.social-nav-revealed {
          -webkit-animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
          animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
        }

        &.social-nav-hidden {
          -webkit-animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        @media (max-width: 767px) {
          &.social-nav-revealed {
            -webkit-animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
            animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
          }

          &.social-nav-hidden {
            -webkit-animation: hide-slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: hide-slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          }
        }
      }
    }

    .main-menu-footer {
      display: flex;
      align-items: center;
      overflow: hidden;

      .footer-link1 {
        transform: translateX(-100%);

        &.footer-link1-revealed {
          -webkit-animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.6s;
          animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.6s;
        }

        &.footer-link1-hidden {
          -webkit-animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
      }

      .footer-link2 {
        transform: translateY(100px);

        &.footer-link2-revealed {
          -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.6s;
          animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.6s;
        }

        &.footer-link2-hidden {
          -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
      }
    }

    @media (max-width: 767px) {
      margin-left: 3rem;
      padding-bottom: 3rem;

      .social-nav {
        display: block;
        transform: translateX(-100%);
      }

      .main-menu-footer {
        display: block;

        .link-text {
          font-size: 1.8rem;
        }
      }
    }
  }
`;

export const MainNav = ({ mainMenuHovered, mainNavOpen, setMainNavOpen }) => {
  let navClassName = '';

  if (mainNavOpen) {
    navClassName = 'main-nav-open';
  } else if (mainMenuHovered) {
    navClassName = 'main-nav-toggle-hovered';
  }

  return (
    <MainNavWrapper className={navClassName}>
      <div className="menu-color-strip" />

      <LanguageSelector />

      <div className="main-menu-content">
        <div className="links-and-address">
          <div>
            <ul>
              <MainNavLink
                link="#home"
                mainNavOpen={mainNavOpen}
                highlighterColor={colors.highlighterColor1}
                setMainNavOpen={setMainNavOpen}
              >
                Home.
              </MainNavLink>
              <MainNavLink
                link="#digital"
                mainNavOpen={mainNavOpen}
                highlighterColor={colors.highlighterColor2}
                setMainNavOpen={setMainNavOpen}
              >
                Digital.
              </MainNavLink>
              <MainNavLink
                link="#portfolio"
                mainNavOpen={mainNavOpen}
                highlighterColor={colors.highlighterColor3}
                setMainNavOpen={setMainNavOpen}
              >
                Projects.
              </MainNavLink>
            </ul>

            <CompanyContact mainNavOpen={mainNavOpen} />

            <SocialNav className={`social-nav ${mainNavOpen ? 'social-nav-revealed' : 'social-nav-hidden'}`} />
          </div>
        </div>

        <div className="main-menu-footer">
          <FooterArrow mainNavOpen={mainNavOpen} />

          <FooterLink
            className={`footer-link1 ${
              mainNavOpen ? 'footer-link1-revealed' : 'footer-link1-hidden'
            } main-menu-footer-section`}
            link="mailto:hello@wpdoctor.se"
            description="Want to work with us ?"
            text="Contact us"
          />
        </div>
      </div>
    </MainNavWrapper>
  );
};
