import { bgColors } from '../../../theme/colors';

let projectDetailBgColors = [...bgColors];

/**
 * Pick a random color from the bgColors array and make sure the same color is not picked consecutively
 * @returns {string}
 */
export const pickRandomBGColor = () => {
  // If all colors are used, re-fill the array
  if (projectDetailBgColors.length === 0) {
    projectDetailBgColors = [...bgColors];
  }

  // Pick a color
  const color = projectDetailBgColors[Math.floor(Math.random() * projectDetailBgColors.length)];

  // Remove the color from the array
  const indexOfColor = projectDetailBgColors.indexOf(color);
  projectDetailBgColors.splice(indexOfColor, 1);

  // Return the color
  return color;
};
