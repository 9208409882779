import styled from 'styled-components';
import { colors } from '../../theme/colors';

const MainNavLinkWrapper = styled.li`
  margin-bottom: 1rem;
  overflow: hidden;
  cursor: pointer;
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 900;

  &::before {
    content: none;
  }

  .main-link-text {
    transition: translateY 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
    transform: translateY(100px);
    color: ${colors.primaryText};
    outline: none;

    span {
      position: relative;

      .highlighter {
        height: 15px;
        display: inline-block;
        width: 0;
        position: absolute;
        margin-top: 10px;
        background-color: ${(props) => props.highlighterColor || colors.highlighterColor1};
        opacity: 0.5;
        z-index: -1;
        transition: width 400ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
      }

      &:hover {
        .highlighter {
          width: 100%;
        }
      }
    }

    &.main-link-text-revealed {
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
    }

    &.main-link-text-hidden {
      -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0.4rem;
    font-size: 2rem;
  }
`;

export const MainNavLink = ({ mainNavOpen, children, highlighterColor, link, setMainNavOpen }) => (
  <MainNavLinkWrapper
    highlighterColor={highlighterColor}
    onClick={() => {
      setMainNavOpen(false);
    }}
  >
    <a href={link} className={`main-link-text ${mainNavOpen ? 'main-link-text-revealed' : 'main-link-text-hidden'}`}>
      <span>
        <div className="highlighter" /> {children}
      </span>
    </a>
  </MainNavLinkWrapper>
);
