import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { Arrow } from '../shared/assets/Arrow';
import { ZigZagLine } from '../shared/assets/ZigZagLine';

const HeroWrapper = styled.section`
  height: 100%;
  position: fixed;
  width: calc(100% - 80px);
  z-index: 2;
  top: 0;

  @media (max-width: 767px) {
    width: 100%;
  }

  .hero {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .hero-bg-section {
    height: 100%;
    width: 50%;
    position: absolute;
  }

  .hero-bg-left-section {
    background-color: ${colors.primaryColor};
    z-index: 1;
    left: 0;
    -webkit-transition: width 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
    -moz-transition: width 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
    transition: width 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;

    &.hero-bg-left-section-expanded {
      width: 100%;
    }
  }

  .hero-bg-right-section {
    background-color: ${colors.secondaryColor};
    right: 0;
  }

  .welcome-text {
    z-index: 90;
    position: absolute;
    font-weight: 900;
    font-size: 25rem;
    transform: translateX(0);
    width: 100%;
    text-align: center;
    margin: 0;

    transition: transform 1000ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;

    span {
      color: ${colors.primaryColor};
      margin-left: -6rem;

      @media (max-width: 767px) {
        margin-left: -3rem;
      }
    }

    &.welcome-text-moved {
      transform: translateX(51%);

      @media (max-width: 767px) {
        transform: translateX(63%);
      }
    }

    @media (max-width: 767px) {
      font-size: 10rem;
    }
  }

  .scroll-button {
    z-index: 90;
    position: absolute;
    bottom: 0;
    margin-bottom: 7rem;
    font-weight: 900;
    font-size: 1rem;
    margin-right: 8rem;
    color: ${colors.secondaryColor};
    border: none;
    outline: none;
    display: flex;
    transform: rotate(-90deg);
    transform-origin: left top;
    opacity: 1;
    transition: opacity 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;

    .scroll-arrow-wrapper {
      margin-top: -0.1rem;
      margin-right: 2rem;
    }

    .scroll-arrow {
      -webkit-animation: bounce 800ms cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
      -moz-animation: bounce 800ms cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
      animation: bounce 800ms cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }

    &.scroll-button-hidden {
      opacity: 0;
    }
  }

  .site-info-wrapper {
    width: 100%;
    position: absolute;
    z-index: 90;
    padding: 20vh 165px 20vh 22%;
    font-size: 2.4rem;
    font-weight: 800;

    @media (max-width: 767px) {
      padding: 40px;
      font-size: 1.6rem;
    }

    .info-container {
      overflow: hidden;
    }

    .site-info {
      width: 66%;
      line-height: 1.2;

      p {
        transition: translateY 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
        transform: translateY(150px);

        &.info-title-revealed {
          -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
          animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
        }

        &.info-title-hidden {
          -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        &.info-text-revealed {
          -webkit-animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
          animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
        }

        &.info-text-hidden {
          -webkit-animation: slide-down 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-down 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
      }
    }

    .age-container {
      overflow: hidden;
    }

    .age {
      font-family: 'Libre Baskerville', serif;
      font-weight: 300;
      font-size: 1.1rem;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      transform: translateX(-100px);

      &.age-revealed {
        -webkit-animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
        animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
      }

      &.age-hidden {
        -webkit-animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }

    .white-intro {
      color: ${colors.secondaryText};
    }

    .zig-zag-line {
      width: 25px;
      margin-right: 8px;
    }
  }
`;

let isFirstLoad = true;

export const Hero = ({ isHeroScrolled }) => {
  if (isFirstLoad && isHeroScrolled) {
    isFirstLoad = false;
  }

  return (
    <HeroWrapper>
      <div className="hero">
        <div className={`hero-bg-section hero-bg-left-section ${isHeroScrolled && 'hero-bg-left-section-expanded'}`} />
        <div className="hero-bg-section hero-bg-right-section" />

        <div className="site-info-wrapper">
          <div className={`site-info ${isHeroScrolled && 'info-revealed'}`}>
            <div className="age-container">
              <div
                className={`age ${!isFirstLoad && isHeroScrolled && 'age-revealed'} ${
                  !isFirstLoad && !isHeroScrolled && 'age-hidden'
                }`}
              >
                <ZigZagLine className="zig-zag-line" /> Since 2019
              </div>
            </div>

            <div className="info-container">
              <p
                className={`${!isFirstLoad && isHeroScrolled && 'info-title-revealed'} ${
                  !isFirstLoad && !isHeroScrolled && 'info-title-hidden'
                }`}
              >
                We are a web agency based in Stockholm
              </p>
            </div>

            <div className="info-container">
              <p
                className={`white-intro ${!isFirstLoad && isHeroScrolled && 'info-text-revealed'} ${
                  !isFirstLoad && !isHeroScrolled && 'info-text-hidden'
                }`}
              >
                We build high-quality websites and web applications
              </p>
            </div>

            <div className="info-container">
              <p
                className={`white-intro ${!isFirstLoad && isHeroScrolled && 'info-text-revealed'} ${
                  !isFirstLoad && !isHeroScrolled && 'info-text-hidden'
                }`}
              >
                with WordPress.
              </p>
            </div>
          </div>
        </div>

        <h2 className={`welcome-text ${isHeroScrolled && 'welcome-text-moved'}`}>
          HELLO <span>!</span>
        </h2>

        <button type="button" className={`scroll-button ${isHeroScrolled && 'scroll-button-hidden'}`}>
          <div className="scroll-arrow-wrapper">
            <Arrow className="scroll-arrow" />
          </div>
          Scroll
        </button>
      </div>
    </HeroWrapper>
  );
};
