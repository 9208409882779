import styled from 'styled-components';
import { Hero } from './Hero';
import { DigitalStrategy } from './DigitalStrategy';
import { Services } from './Services';
import { Projects } from './Projects';

const HomeWrapper = styled.div`
  .scroll-content {
    position: relative;
    z-index: 90;
  }

  .padding-space {
    height: 1800px;

    @media (max-width: 767px) {
      height: 800px;
    }
  }

  .video-visual {
    width: 100%;
    display: block;
  }

  @media (min-width: 1200px) {
    .digital-strategy-and-services {
      display: flex;
    }
  }
`;

export const Home = ({ isHeroScrolled }) => (
  <HomeWrapper id="home">
    <Hero isHeroScrolled={isHeroScrolled} />

    <div className="scroll-content">
      <div className="padding-space" />

      <div className="digital-strategy-and-services">
        <DigitalStrategy />

        <Services />
      </div>

      <Projects />
    </div>
  </HomeWrapper>
);
