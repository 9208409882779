import styled from 'styled-components';
import { HomeTextSection } from './HomeTextSection';

const DigitalStrategyWrapper = styled.section`
  @media (min-width: 1200px) {
    width: 50%;
  }
`;

export const DigitalStrategy = () => (
  <DigitalStrategyWrapper id="digital">
    <HomeTextSection
      title={<span>DIGITAL.</span>}
      summary={
        <p>
          An <span className="section-summary-highlighted">overview</span>
          <br />
          of our digital
          <br />
          services<span className="section-summary-full-stop">.</span>
        </p>
      }
      description=""
      linkText="Our values"
    />
  </DigitalStrategyWrapper>
);
