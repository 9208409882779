import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { FooterArrow } from './FooterArrow';
import { FooterLink } from './FooterLink';
import { CompanyContact } from './CompanyContact';

const FooterWrapper = styled.footer`
  position: relative;
  background-color: ${colors.secondaryText};
  z-index: 1002;
  display: flex;
  align-items: center;
  padding: 6rem 4rem;

  .footer-copyright {
    color: ${colors.dimText};
    text-align: right;
    line-height: 1.5;
    font-size: 1.1rem;
  }

  .footer-links {
    flex: 1;
  }

  @media (max-width: 992px) {
    .footer-company-contact {
      display: none;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;

    .footer-copyright {
      text-align: center;
    }

    .footer-links {
      margin-bottom: 2rem;
    }
  }
`;

export const Footer = () => (
  <FooterWrapper>
    <FooterArrow mainNavOpen />

    <div className="footer-links">
      <FooterLink link="mailto:hello@wpdoctor.se" description="Want to work with us ?" text="Contact us" />
    </div>

    <div className="footer-links footer-company-contact">
      <CompanyContact mainNavOpen />
    </div>

    <div className="footer-copyright">
      <span>© WP doctor</span>
      <br />
      <span>Business Reg No: 199204236799</span>
      <br />
      <span>VAT No: SE920423679901</span>
      <br />
      <span>All rights reserved.</span>
    </div>
  </FooterWrapper>
);
