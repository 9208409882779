export const colors = {
  primaryColor: '#CE8954',
  secondaryColor: '#f8f8f8',
  primaryText: '#484242',
  secondaryText: '#ffffff',
  secondaryTitle: '#414a6b',
  darkText: '#0a0a0a',
  dimText: '#4c4a57',
  darkBgDimText: '#d7d7d7',
  inactiveText: '#bcbcbc',
  highlighterColor1: '#ff5851',
  highlighterColor2: '#f3c130',
  highlighterColor3: '#414a6b',
  highlighterColor4: '#d8d8e8',
  highlighterColor5: '#b49a85',
  bgColor1: '#e3544a',
  bgColor2: '#263477',
  bgColor3: '#555a61',
  bgColor4: '#f7c700',
  bgColor5: '#33255e',
  bgColor6: '#1d74ba',
  bgColor7: '#abc560',
  bgColor8: '#9a2429',
};

export const bgColors = [
  colors.bgColor1,
  // colors.bgColor2,
  colors.bgColor4,
  // colors.bgColor5,
  colors.bgColor6,
  colors.bgColor7,
  '#007983',
  '#5e84e9',
];
