import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const TextSectionSummary = styled.div`
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 1.2;

  @media (max-width: 767px) {
    font-size: 2.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 3.8rem;
  }

  .section-summary-highlighted {
    font-family: 'Libre Baskerville', serif;
    font-weight: 300;
    font-style: italic;
  }

  .section-summary-full-stop {
    color: ${colors.primaryColor};
  }
`;

export const TextSectionDescription = styled.div`
  font-size: 1.4rem;
  margin-top: 5rem;
  max-width: 750px;

  @media (max-width: 767px) {
    margin-top: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.7rem;
  }
`;
