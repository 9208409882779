import styled from 'styled-components';
import { colors } from '../../theme/colors';

const FooterWrapper = styled.footer`
  height: 40px;
  width: calc(100% - 80px);
  z-index: 1000;
  background-color: ${colors.secondaryText};
  position: fixed;
  bottom: 0;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BottomPadding = () => <FooterWrapper />;
