import styled from 'styled-components';
import { useState } from 'react';
import { TopNav } from './modules/app/TopNav';
import { MainNavToggle } from './modules/app/MainNavToggle';
import { MainNav } from './modules/app/MainNav';
import { SocialNav } from './modules/app/SocialNav';
import { BottomPadding } from './modules/app/BottomPadding';
import { Home } from './modules/home/Home';
import { Footer } from './modules/app/Footer';

const AppWrapper = styled.div`
  height: 100vh;
  padding: 40px;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;
  }

  .main-header {
    position: absolute;
    top: 0;
    width: calc(100% - 80px);
  }

  .app-content {
    height: 100%;
    position: relative;
  }
`;

function App() {
  const [mainNavHovered, setMainNavHovered] = useState(false);
  const [mainNavOpen, setMainNavOpen] = useState(false);
  const [isHeroScrolled, setIsHeroScrolled] = useState(false);

  const scrollTrigger = () => {
    const scrollPosition = window.scrollY;

    setIsHeroScrolled(scrollPosition > 10);
  };

  return (
    <AppWrapper>
      <MainNav
        className="menu"
        mainMenuHovered={mainNavHovered}
        mainNavOpen={mainNavOpen}
        setMainNavOpen={setMainNavOpen}
      />
      <SocialNav />

      <header className="main-header">
        <TopNav isHeroScrolled={isHeroScrolled} />
      </header>

      <MainNavToggle setMainNavHovered={setMainNavHovered} setMainNavOpen={setMainNavOpen} mainNavOpen={mainNavOpen} />

      <div className="app-content" onTouchMove={scrollTrigger} onWheel={scrollTrigger}>
        <Home isHeroScrolled={isHeroScrolled} />

        <Footer />
      </div>

      <BottomPadding />
    </AppWrapper>
  );
}

export default App;
