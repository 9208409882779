import styled from 'styled-components';
import { colors } from '../../theme/colors';

const MainNavToggleWrapper = styled.div`
  position: fixed;
  z-index: 1006;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  margin-left: -5px;

  @media (max-width: 767px) {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 3rem;
    padding-right: 3rem;
  }

  .main-nav-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;

    @media (max-width: 767px) {
      background-color: ${colors.secondaryText};
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      margin-left: calc(100% - 4rem);
    }

    &:hover {
      .menu-line-1:not(.menu-line-close) {
        height: 18px;
      }

      .menu-line-2:not(.menu-line-close) {
        height: 32px;
      }

      .menu-line-3:not(.menu-line-close) {
        height: 20px;
      }
    }
  }

  .main-nav-toggle-text {
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      display: none;
    }

    span {
      font-size: 0.7rem;
      font-weight: 900;
      transform: rotate(-90deg);
      letter-spacing: 0.2em;
    }
  }

  .menu-icon {
    transform: rotate(90deg);
    margin-right: 10px;
    height: 11px;
    display: flex;

    @media (max-width: 767px) {
      margin-right: -5px;
      margin-top: -3px;
    }
  }

  .menu-line {
    background-color: ${colors.primaryText};
    width: 2px;
    display: inline-block;
    margin: 0 1.5px;
    transition: all 200ms ease-out;

    &.menu-line-1 {
      height: 12px;

      &.menu-line-close {
        height: 16px;
        transform: rotate(45deg) translate(-2px, 3px);
      }
    }

    &.menu-line-2 {
      height: 20px;

      &.menu-line-close {
        opacity: 0;
      }
    }

    &.menu-line-3 {
      height: 16px;

      &.menu-line-close {
        transform: rotate(-45deg) translate(4px, 5px);
      }
    }
  }
`;

export const MainNavToggle = ({ setMainNavHovered, setMainNavOpen, mainNavOpen }) => {
  const mainNavToggleHover = () => {
    setMainNavHovered(true);
  };

  const mainNavToggleHoverExit = () => {
    setMainNavHovered(false);
  };

  return (
    <MainNavToggleWrapper>
      <button
        aria-label="Open Menu"
        type="button"
        className="main-nav-toggle"
        onMouseEnter={mainNavToggleHover}
        onMouseLeave={mainNavToggleHoverExit}
        onClick={() => {
          setMainNavOpen(!mainNavOpen);
        }}
      >
        <i className="menu-icon">
          <span className={`menu-line menu-line-3 ${mainNavOpen && 'menu-line-close'}`} />
          <span className={`menu-line menu-line-2 ${mainNavOpen && 'menu-line-close'}`} />
          <span className={`menu-line menu-line-1 ${mainNavOpen && 'menu-line-close'}`} />
        </i>

        <div className="main-nav-toggle-text">
          <span>MENU</span>
        </div>
      </button>
    </MainNavToggleWrapper>
  );
};
