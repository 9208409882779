import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { ZigZagLine } from '../shared/assets/ZigZagLine';
import { TextSectionDescription, TextSectionSummary } from './StyledComponents';

const HomeTextSectionWrapper = styled.section`
  background-color: ${colors.secondaryColor};
  padding: 10rem 14rem;

  @media (max-width: 767px) {
    padding: 4rem 2rem;
  }

  .zig-zag-line {
    stroke: ${(props) => props.titleColor || colors.primaryColor};
    width: 50px;
    padding: 4px;
  }

  .section-title {
    font-weight: 900;
    font-size: 8rem;
    color: ${(props) => props.titleColor || colors.primaryColor};
    line-height: 1;
    margin-top: 2rem;
    margin-bottom: 5rem;

    @media (max-width: 767px) {
      font-size: 5rem;
      margin-bottom: 2rem;
    }

    @media (min-width: 1200px) {
      font-size: 11rem;
    }
  }
`;

export const HomeTextSection = ({ title, summary, description, titleColor }) => (
  <HomeTextSectionWrapper className="home-text-section" titleColor={titleColor}>
    <ZigZagLine className="zig-zag-line" />
    <h2 className="section-title">{title}</h2>

    <TextSectionSummary className="section-summary">{summary}</TextSectionSummary>

    <TextSectionDescription className="section-description">{description}</TextSectionDescription>
  </HomeTextSectionWrapper>
);
