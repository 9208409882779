import styled from 'styled-components';
import { colors } from '../../theme/colors';

const ProjectWrapper = styled.a`
  text-decoration: none;
  outline: none;
  position: relative;
  overflow: hidden;

  .project-image {
    background: url(${(props) => props.projectImageURL}) no-repeat center center;
    background-size: cover;
    height: 310px;
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      height: 450px;
    }
  }

  .project-header {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 4rem;
    padding-top: 2rem;
    z-index: 90;
    transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
    transform: translateY(0);
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.1), rgba(255, 255, 255, 1));
}
  }

  .project-type {
    color: ${colors.primaryColor};
    font-family: 'Libre Baskerville', serif;
    font-size: 1.5rem;
    font-weight: 800;
    transition: color 800ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .project-title {
    color: ${colors.primaryText};
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 4rem;

    @media (max-width: 767px) {
      font-size: 2.4rem;
    }
  }

  .project-details {
    z-index: 90;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 4rem;
    margin-bottom: 2rem;
    color: ${colors.secondaryText};
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
    max-width: 300px;
    opacity: 0;
    -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    transition: opacity 800ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;

    @media (max-width: 767px) {
      font-size: 2.4rem;
      margin-bottom: 2.5rem;
    }
  }

  .project-details-bg {
    background-color: ${(props) => props.bgColor || colors.primaryText};
    color: ${colors.secondaryText};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 101%;
    height: 0;
    transition: all 400ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;
    transform: translateY(180px) skewY(25deg);

    @media (min-width: 1200px) {
      transform: translateY(980px) skewY(25deg);
    }
  }

  &:hover {
    .project-details-bg {
      height: 100%;
      transform: translateY(0px) skewY(0deg);
    }

    .project-header {
      transform: translateY(-5rem);
      background-image: none;
    }

    .project-details {
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0s;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0s;
      opacity: 1;
    }

    .project-type {
      color: ${colors.primaryText};
    }
  }
`;

export const Project = ({ projectImageURL, title, summary, link, projectType, bgColor }) => (
  <ProjectWrapper
    bgColor={bgColor}
    className="project"
    projectImageURL={projectImageURL}
    rel="noreferrer"
    target="_blank"
    href={link}
  >
    <div className="project-image">
      <div className="project-header">
        <span className="project-type">{projectType}</span>
        <h3 className="project-title">{title}</h3>
      </div>
    </div>

    <div className="project-details-bg" />
    <div className="project-details">
      <span className="project-summary">{summary}</span>
    </div>

    <div className="project-link" />
  </ProjectWrapper>
);
