export const ZigZagLine = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 412.8 72.9"
    xmlSpace="preserve"
  >
    <g id="Layer_2_1_">
      <g id="Capa_1">
        <path
          className="st0"
          style={{
            strokeWidth: 26,
            strokeMiterlimit: 20,
          }}
          d="M5,7.8c0,0.7,0.3,1.5,0.8,2l50.7,50.7c1.1,1.1,2.9,1.1,4,0c0,0,0,0,0,0l46.7-46.7L156,62.6
			c1.1,1.1,2.9,1.1,4,0L206.5,16l49,49c1.1,1.1,2.9,1.1,4,0c0,0,0,0,0,0l46.7-46.7L355,67.1c1.1,1.1,2.9,1.1,4,0c0,0,0,0,0,0
			l48-48.1c1.1-1.1,1.1-2.9,0-4s-2.9-1.1-4,0l-46.1,46.2l-48.7-48.7c-1.1-1.1-2.8-1.1-3.9-0.1c0,0,0,0-0.1,0.1
			c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.3l-46.1,46.2l-48.7-48.7c-0.7-0.7-1.6-0.9-2.5-0.7c-0.8,0-1.5,0.3-2.1,0.8
			l-46.2,46.2L109.2,7.9c-1.1-1.1-2.9-1.1-4,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.4,0.3L58.5,54.6L9.8,5.8
			c-1.1-1.1-2.9-1.1-4,0c0,0,0,0,0,0C5.3,6.3,5,7.1,5,7.8z"
        />
      </g>
    </g>
  </svg>
);
