import styled from 'styled-components';
import { Service } from './Service';
import { colors } from '../../theme/colors';

const ServicesWrapper = styled.section`
  background-color: ${colors.primaryText};
  padding: 8rem 14rem;
  display: flex;

  @media (max-width: 767px) {
    display: block;
    padding: 6rem 4rem;
  }

  @media (min-width: 1200px) {
    width: 50%;
    padding: 8rem 6rem;
    display: flex;
    align-items: center;
  }

  @media (min-width: 1400px) {
    padding: 8rem 14rem;
  }

  .service-group {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
    }

    .service:first-child {
      margin-bottom: 4rem;
    }

    @media (max-width: 767px) {
      .service:not(:last-child) {
        margin-bottom: 4rem;
      }
    }
  }
`;

export const Services = () => (
  <ServicesWrapper>
    <div className="service-group">
      <Service
        title="Websites & App Development"
        subServices={['Front end & Back end', 'WordPress', 'PHP', 'React Js', 'Headless CMS']}
      />
      <Service
        title="Digital & Mobile"
        subServices={['Websites', 'Responsive design', 'Mobile & Tablet', 'Mobile apps (iOS, Android)']}
      />
    </div>

    <div className="service-group">
      <Service
        title="WordPress Services"
        subServices={[
          'Custom theme/plugin development',
          'Update old WP sites to latest',
          'WooCommerce intergrations',
          'BuddyBoss intergrations',
        ]}
      />
      <Service
        title="Other Services"
        subServices={[
          'Websites & App maintenance',
          'Malware Removal',
          'Improve overall performance',
          'Third party API intergrations',
          'Google core web vitals',
        ]}
      />
    </div>
  </ServicesWrapper>
);
