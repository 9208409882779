import styled from 'styled-components';

const CompanyContactWrapper = styled.div`
  overflow: hidden;

  &.contact-info-revealed {
    -webkit-animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
    animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
  }

  &.contact-info-hidden {
    -webkit-animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  span,
  a {
    display: block;
  }

  .contact {
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 900;
  }

  .contact-info {
    font-size: 1.1rem;
    line-height: 1.5;
    transform: translateX(-100%);

    &.contact-info-revealed {
      -webkit-animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
      animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
    }

    &.contact-info-hidden {
      -webkit-animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    span,
    a {
      display: block;
    }

    .contact {
      font-size: 1.3rem;
      line-height: 1.5;
      font-weight: 900;
    }
  }

  @media (max-width: 767px) {
    &.contact-info-revealed {
      -webkit-animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
      animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
    }

    &.contact-info-hidden {
      -webkit-animation: hide-slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: hide-slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
  }
`;

export const CompanyContact = ({ mainNavOpen }) => (
  <CompanyContactWrapper className="contact-info-container">
    <div className={`contact-info ${mainNavOpen ? 'contact-info-revealed' : 'contact-info-hidden'}`}>
      <span className="address">Söra Skolgränd 1, LGH 1005, 18439, Åkersberga, Sweden</span>

      <span className="contact">+46(0)73 781 36 46</span>

      <span className="contact">hello@wpdoctor.se</span>
    </div>
  </CompanyContactWrapper>
);
