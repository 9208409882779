import styled from 'styled-components';
import { colors } from '../../theme/colors';

const LanguageSelectorWrapper = styled.div`
  font-size: 0.9rem;
  font-weight: 900;

  a {
    color: ${colors.inactiveText};

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active-language {
      color: ${colors.primaryText};
    }
  }
`;

export const LanguageSelector = () => (
  <LanguageSelectorWrapper className="language-selector">
    <a href="/" className="active-language">
      EN.
    </a>
    <a href="/">SV.</a>
  </LanguageSelectorWrapper>
);
