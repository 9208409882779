import styled from 'styled-components';

const FooterArrowImg = styled.img`
  transform: translateX(-100%);
  width: 40px;
  margin-right: 3rem;

  @media (max-width: 767px) {
    display: none;
  }

  &.footer-arrow-revealed {
    -webkit-animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
    animation: slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
  }

  &.footer-arrow-hidden {
    -webkit-animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: hide-slide-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`;

export const FooterArrow = ({ mainNavOpen }) => (
  <FooterArrowImg
    className={`footer-arrow ${mainNavOpen ? 'footer-arrow-revealed' : 'footer-arrow-hidden'} menu-arrow`}
    src="/img/long-arrow.svg"
    alt="Long Arrow"
  />
);
