import styled from 'styled-components';
import { ZigZagLine } from '../shared/assets/ZigZagLine';
import { colors } from '../../theme/colors';

const ServiceWrapper = styled.div`
  .service-title {
    color: ${colors.secondaryText};
    font-weight: 900;
    font-size: 1.6rem;
    margin-top: 0.2rem;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .zig-zag-line {
    stroke: ${colors.secondaryColor};
    width: 17px;
  }

  .sub-service {
    color: ${colors.darkBgDimText};
    font-size: 1.6rem;
  }
`;

export const Service = ({ title, subServices }) => (
  <ServiceWrapper className="service">
    <ZigZagLine className="zig-zag-line" />

    <h3 className="service-title">{title}</h3>

    <ul>
      {subServices.map((subService) => (
        <li className="sub-service" key={subService}>
          {subService}
        </li>
      ))}
    </ul>
  </ServiceWrapper>
);
