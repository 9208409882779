import styled from 'styled-components';
import { colors } from '../../theme/colors';

const FooterLinkWrapper = styled.a`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: ${colors.primaryText};

  &:hover {
    color: ${colors.primaryText};
  }

  .link-description {
    font-family: 'Libre Baskerville', serif;
    font-style: italic;
    font-weight: 300;
    font-size: 1.1rem;
  }

  .link-text {
    font-weight: 900;
    font-size: 2.4rem;
    text-transform: uppercase;
    line-height: 1.4;
  }
`;

export const FooterLink = ({ className, link, description, text }) => (
  <FooterLinkWrapper className={className} href={link}>
    <span className="link-description">{description}</span>
    <span className="link-text">{text}</span>
  </FooterLinkWrapper>
);
