import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { LanguageSelector } from './LanguageSelector';

const TopNavWrapper = styled.nav`
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  position: fixed;
  width: calc(100% - 80px);
  z-index: 1000;
  background-color: ${colors.secondaryText};
  transition: opacity 1000ms cubic-bezier(0.7, 0, 0.3, 1) 50ms;

  @media (max-width: 767px) {
    opacity: ${(props) => (props.isHeroScrolled ? '0' : '1')};
    background-color: transparent;
    padding: 4.9rem 0 0 3rem;
  }

  .site-title {
    flex: 1;
    font-weight: 900;
    font-size: 1.6rem;
    z-index: 99999999;

    a {
      outline: none;
      color: ${colors.primaryText};
    }

    .logo-black {
      display: block;
    }

    .logo-white {
      display: none;
    }

    @media (max-width: 767px) {
      .logo-black {
        display: none;
      }

      .logo-white {
        display: block;
      }
    }
  }

  @media (max-width: 767px) {
    .language-selector {
      display: none;
    }
  }
`;

export const TopNav = ({ isHeroScrolled }) => (
  <TopNavWrapper isHeroScrolled={isHeroScrolled}>
    <div className="site-title">
      <a href="/">
        <img src="/img/logo-full-black.svg" alt="WP Doctor Logo" className="logo logo-black" width={112} height={27} />
        <img src="/img/logo-full-white.svg" alt="WP Doctor Logo" className="logo logo-white" width={112} height={27} />
      </a>
    </div>

    <LanguageSelector />
  </TopNavWrapper>
);
