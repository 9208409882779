import styled from 'styled-components';

const SocialNavWrapper = styled.nav`
  position: fixed;
  height: calc(100% - 80px);
  z-index: 4;
  display: flex;
  align-items: center;
  right: 0;
  width: 40px;
  justify-content: center;

  @media (max-width: 767px) {
    position: static;
  }

  .social-links {
    display: flex;
    flex-direction: column;

    .fb-icon {
      width: 6px;
      height: 13px;
    }

    .insta-icon {
      width: 13px;
      height: 13px;
    }

    .twitter-icon {
      width: 13px;
      height: 10px;
    }

    @media (max-width: 767px) {
      flex-direction: row;
      width: 100px;
      margin-top: 2rem;
      display: flex;
      align-items: center;

      .social-link {
        margin-right: 1rem;
      }
    }
  }

  .social-link {
    outline: none;

    @media (min-width: 768px) {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .social-link-icon {
    margin: auto;
    display: block;
  }
`;

export const SocialNav = ({ className }) => (
  <SocialNavWrapper className={`social-nav ${className}`}>
    <div className="social-links">
      <a href="/" className="social-link">
        <img src="/img/facebook-icon.svg" alt="Facebook" className="social-link-icon fb-icon" width={6} height={13} />
      </a>

      <a href="/" className="social-link">
        <img
          src="/img/instagram-icon.svg"
          alt="Instagram"
          className="social-link-icon insta-icon"
          width={13}
          height={13}
        />
      </a>

      <a href="/" className="social-link">
        <img
          src="/img/twitter-icon.svg"
          alt="Twitter"
          className="social-link-icon twitter-icon"
          width={13}
          height={10}
        />
      </a>
    </div>
  </SocialNavWrapper>
);
