import styled from 'styled-components';
import { Project } from './Project';
import { pickRandomBGColor } from './functions/pickRandomBGColor';
import { HomeTextSection } from './HomeTextSection';

const ProjectsWrapper = styled.section`
  #projects {
    display: flex;
    flex-wrap: wrap;
  }

  .project {
    width: 50%;
    flex: 0 0 auto;

    @media (min-width: 1200px) {
      width: 33.33%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  @media (min-width: 2000px) {
    display: flex;

    .home-text-section,
    #projects {
      width: 50%;
    }
  }
`;

export const Projects = () => (
  <ProjectsWrapper id="portfolio">
    <HomeTextSection
      title={<span>PORTFOLIO.</span>}
      summary={
        <p>
          Our <span className="section-summary-highlighted">recent</span>
          <br />
          projects<span className="section-summary-full-stop">.</span>
        </p>
      }
      description=""
      linkText="Our values"
    />

    <div id="projects">
      <Project
        projectImageURL="/img/project-mariadb.jpg"
        title="MariaDB Corporation"
        summary="Enterprise Open Source Database."
        link="https://mariadb.com/"
        projectType="Website"
        bgColor={pickRandomBGColor()}
      />
      <Project
        projectImageURL="/img/project-airportcars.jpg"
        title="Airport Cars."
        summary="Reliable airport transfers."
        link="https://airportcars.com/"
        projectType="Website"
        bgColor={pickRandomBGColor()}
      />
      <Project
        projectImageURL="/img/project-myairportcars.jpg"
        title="Airport Cars."
        summary="Reliable airport transfers."
        link="https://my.airportcars.com/"
        projectType="Taxi booking engine"
        bgColor={pickRandomBGColor()}
      />
      <Project
        projectImageURL="/img/project-roger.jpg"
        title="Roger.com"
        summary="Best online slots, bingo, casino."
        link="https://www.roger.com/"
        projectType="Website"
        bgColor={pickRandomBGColor()}
      />
      <Project
        projectImageURL="/img/project-sustainchange.jpg"
        title="Sustain Change."
        summary="Välmående människor."
        link="https://app.sustainchange.se/"
        projectType="Task management app & Dashboard"
        bgColor={pickRandomBGColor()}
      />
      <Project
        projectImageURL="/img/project-tiki.jpg"
        title="Tiki medical."
        summary="Andningsskydd designad för vård."
        link="https://tikimed.se/"
        projectType="Website"
        bgColor={pickRandomBGColor()}
      />
    </div>
  </ProjectsWrapper>
);
